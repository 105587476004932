import { PickersLocaleText } from '@mui/x-date-pickers';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useMemo } from 'react';

const useLocaleText = (t: TFunction) => {
  return useMemo(
    (): Partial<PickersLocaleText<moment.Moment>> => ({
      openDatePickerDialogue: (date) =>
        date ? t('open-date-picker-dialogue', { formattedDate: date.format('ll') }) : t('select-date'),
    }),
    [t],
  );
};

export default useLocaleText;
