import React from 'react';

interface iOnboarding {
  title?: string;
}

const Onboarding: React.FC<iOnboarding> = function Onboarding({ title }: iOnboarding): React.JSX.Element {
  return <div>{title}</div>;
};

export default Onboarding;
