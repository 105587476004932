import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iReleaseNote } from 'datamodel';

export const releaseNotesSlice = createSlice({
  name: 'releaseNotes',
  initialState: [] as iReleaseNote[],
  reducers: {
    setReleaseNotesValue: (_, action: PayloadAction<iReleaseNote[]>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setReleaseNotesValue } = releaseNotesSlice.actions;

export default releaseNotesSlice.reducer;
