import { IsArray, IsOptional, IsString } from 'class-validator';
import {
  iManagerDataForTable,
  iManagerLifecycleAverageData,
  iManagerMostRequiredData,
  iManagerMostRequiredShift,
  iManagerReportData,
  iManagerTopThreeLeasingEmployees,
  iManagerUserNumbers,
} from '../../Interfaces/Manager';

export class AddOnManagerGetFullReportDto {
  @IsString()
  startDate: string;
  @IsString()
  endDate: string;
  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  departments: string[];
  @IsArray()
  @IsString({ each: true })
  @IsOptional()
  shifts: string[];
}

export class AddOnManagerGetFullReportResultResponseDto {
  message: string;
  data: AddOnManagerGetFullReportResultDto;
  duration: {
    total: number;
    allTimes: string[];
  };
}
export class AddOnManagerGetFullReportResultDto {
  offers: {
    offerVsAssigned: {
      offers: number;
      assigned: number;
      sum: number;
    };
    acceptedVsReject: {
      sum: number;
      accepted: number;
      rejected: number;
    };
    department: {
      departmentFlow: iManagerMostRequiredData[];
    };
    positiveUsage: iManagerDataForTable[];
    averageCreatedToAccepted: iManagerLifecycleAverageData;
    averageAcceptedToAssigned: iManagerLifecycleAverageData;
    averageCreatedToAssigned: iManagerLifecycleAverageData;
    averageCreatedToAssignedTimeFrame: iManagerLifecycleAverageData;
    demandedDep: iManagerMostRequiredData;
    demandedShift: iManagerMostRequiredData;
    demandedSkill: iManagerMostRequiredData;
    mostEngagedDept: iManagerMostRequiredData;
    mostEngagedShift: iManagerMostRequiredData;
    mostEngagedSkill: iManagerMostRequiredData;
    reportData: iManagerReportData;
    demandedShiftPerDept: iManagerMostRequiredShift[];
    multiTenantItems: number;
    multiTenantBooked: number;
    averageLead: { days: number; hours: number; minutes: number };
    averageAccept: string;
    averageReject: string;
    shortTermCount: number;
    mediumTermCount: number;
  };
  users: {
    userNumbers: iManagerUserNumbers[];
  };
  allOffers: {
    averageLifecycle: iManagerLifecycleAverageData;
  };
  general: {
    allRequestedShiftTypes: number[];
    allBookedShiftTypes: number[];
  };
  leasing: {
    mostRequestedDepartment: iManagerMostRequiredData;
    mostRequestedShift: iManagerMostRequiredData;
    mostRequestedQualification: iManagerMostRequiredData;
    averageLeadTime: { days: number; hours: number; minutes: number };
    shortTermCount: number;
    mediumTermCount: number;
    topThreePartners: iManagerMostRequiredData[];
    topThreeEmployees: iManagerTopThreeLeasingEmployees[];
    booked: number;
    count: number;
  };
  pool: {
    mostRequestedDepartment: iManagerMostRequiredData;
    mostRequestedShift: iManagerMostRequiredData;
    mostRequestedQualification: iManagerMostRequiredData;
    averageLeadTime: { days: number; hours: number; minutes: number };
    shortTermCount: number;
    mediumTermCount: number;
    medianWorkloadPerDay: number;
    booked: number;
  };
  poolAbsence: {
    series: { name: string; data: (number | null)[] }[];
    colorsArray: string[];
    chartLabels: string[];
  };
  volunteer: {
    booked: number;
    bookedCount: number[];
    count: number;
    countCount: number[];
    departmentNames: string[];
  };
}
