import { checkCustomerId, checkTenantId } from 'database';
import { iExportSettingsPoolManagement } from 'datamodel';
import { iExportSettings } from 'datamodel/src/Interfaces/Settings/index';
import { collection, CollectionReference, doc, DocumentData, DocumentReference, setDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getSettingsRef = (): CollectionReference<DocumentData> | false => {
  const customerId = checkCustomerId();
  const tenantId = checkTenantId();
  if (!customerId || !tenantId) return false;
  const settings = collection(db, 'customers', customerId, 'houses', tenantId, 'settings');
  return settings;
};

export const getSettingsLimitsRef = (): DocumentReference<DocumentData> | false => {
  const settingsRef = getSettingsRef();
  if (!settingsRef) return false;
  const limitsRef = doc(settingsRef, 'limits');
  return limitsRef;
};

export const getSettingsExportRef = (): DocumentReference<DocumentData> | false => {
  const settingsRef = getSettingsRef();
  if (!settingsRef) return false;
  const exportRef = doc(settingsRef, 'export');
  return exportRef;
};

export const getSettingsExportPoolManagementRef = (): DocumentReference<DocumentData> | false => {
  const settingsRef = getSettingsRef();
  if (!settingsRef) return false;
  const exportRef = doc(settingsRef, 'exportPoolManagement');
  return exportRef;
};

export const updateSettingsExport = async (value: Partial<iExportSettings>): Promise<void> => {
  const exportRef = getSettingsExportRef();
  if (!exportRef) return;
  await setDoc(exportRef, value, { merge: true });
};

export const updateExportPoolManagement = async (value: Partial<iExportSettingsPoolManagement>): Promise<void> => {
  const exportRef = getSettingsExportPoolManagementRef();
  if (!exportRef) return;
  await setDoc(exportRef, value, { merge: true });
};
