import { IsEnum, IsNotEmpty, IsOptional, IsString } from 'class-validator';
import { eApiTrackingAction } from '../../Enums';
import { iApiTracking } from '../../Interfaces/Api';

export class CreateTrackingDto implements iApiTracking {
  @IsString()
  @IsOptional()
  customerId: string;

  @IsString()
  @IsOptional()
  houseId: string;

  @IsNotEmpty()
  @IsString()
  userId: string;

  @IsNotEmpty()
  @IsEnum(eApiTrackingAction)
  action: eApiTrackingAction;

  @IsString()
  @IsOptional()
  page: string;

  @IsString()
  @IsOptional()
  objectId: string;
}
