import { checkCustomerId, checkUserId } from 'database';
import { iNews } from 'datamodel';
import { addDoc, collection, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from 'localFirebase';

export const getNewsRef = () => {
  const customerId = checkCustomerId();
  const uid = checkUserId();
  if (!customerId) return false;
  if (!uid) return false;
  const customerNews = collection(db, 'customers', customerId, 'news');
  return customerNews;
};

export const deleteNewsById = async (id: string): Promise<void> => {
  const nRef = getNewsRef();
  if (!nRef) return;
  const newsRef = doc(nRef, id);
  if (!newsRef) return;
  const res = await deleteDoc(newsRef);
  return res;
};

export const createNews = async (news: Partial<iNews>): Promise<string | false> => {
  const newsRef = getNewsRef();
  if (!newsRef) return false;
  const newDoc = await addDoc(newsRef, news);
  return newDoc.id;
};

export const updateNews = async (newsId: string, value: Partial<iNews>): Promise<void> => {
  const newsRef = getNewsRef();
  if (!newsRef) return;
  const res = await updateDoc(doc(newsRef, newsId), value);
  return res;
};
