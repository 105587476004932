export enum eShiftSwap {
  OFFER = 'SHIFT_SWAP_OFFER',
  REQUEST = 'SHIFT_SWAP_REQUEST',
}

export enum eShiftSwapRequestType {
  SWAP = 'SHIFT_SWAP',
  RELEASE = 'SHIFT_RELEASE',
}

export enum eShiftSwapRequestStatus {
  NEW = 'NEW',
  POST_MANAGER_APPROVED = 'POST_MANAGER_APPROVED',
  POST_MANAGER_REJECTED = 'POST_MANAGER_REJECTED',
  CANCELLED_BY_USER = 'CANCELLED_BY_USER',
  OFFERER_MANAGER_ACCEPTED = 'OFFERER_MANAGER_ACCEPTED',
  OFFERER_MANAGER_REJECTED = 'OFFERER_MANAGER_REJECTED',
}

export interface iShiftSwapRequest {
  id: string;
  status: eShiftSwapRequestStatus;
  postManagerId: string | null;
  offererManagerId: string | null;
  note: string | null;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
  shiftId: string;
  when: Date;
  departmentId: string;
  requestType: eShiftSwapRequestType;
  receivableShiftIds: string[] | null;
  receivableDates: Date[] | null;
}

export enum eShiftSwapOfferStatus {
  NEW = 'NEW',
  POST_MANAGER_APPROVED = 'POST_MANAGER_APPROVED',
  POST_MANAGER_REJECTED = 'POST_MANAGER_REJECTED',
  REQUESTOR_ACCEPTED = 'REQUESTOR_ACCEPTED',
  REQUESTOR_REJECTED = 'REQUESTOR_REJECTED',
  CANCELLED_BY_USER = 'CANCELLED_BY_USER',
  REQUESTOR_MANAGER_ACCEPTED = 'REQUESTOR_MANAGER_ACCEPTED',
  REQUESTOR_MANAGER_REJECTED = 'REQUESTOR_MANAGER_REJECTED',
}

export interface iShiftSwapOffer {
  id: string;
  shiftSwapRequestId: string;
  status: eShiftSwapOfferStatus;
  postManagerId: string | null;
  requestorManagerId: string | null;
  note: string | null;
  departmentId: string;
  shiftId: string;
  when: Date;
  type: eShiftSwap.OFFER;
  createdBy: string;
  createdAt: Date;
  updatedBy: string;
  updatedAt: Date;
}

export interface iRejectedShiftSwapRequest {
  userId: string;
  shiftSwapRequestId: string;
}
