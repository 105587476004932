import React from 'react';

interface iXlsUpload {
  title?: string;
}

const XlsUpload: React.FC<iXlsUpload> = function XlsUpload({ title }: iXlsUpload): React.JSX.Element {
  return <div>{title}</div>;
};

export default XlsUpload;
