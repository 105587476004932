import {
  Box,
  Button,
  Container,
  Divider,
  Grid2,
  Paper,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Onboarding from './views/Onboarding';
import Questionnaire from './views/Questionnaire';
import WelcomeContent from './views/Welcome';
import XlsDownload from './views/XlsDownload';
import XlsUpload from './views/XlsUpload';

const steps = [
  'welcome-page.greetings',
  'welcome-page.questionnaire',
  'welcome-page.xls-download',
  'welcome-page.xls-upload',
  'welcome-page.finished',
];

const WelcomePage: React.FC = function WelcomePage(): React.JSX.Element {
  const params = useParams();
  const { t } = useTranslation();
  const theme = useTheme();
  const [resolvedName, setResolvedName] = React.useState<string>('');
  const [activeStep, setActiveStep] = React.useState(0);
  const [customerIdentification, setCustomerIdentification] = React.useState<string>(params.id || '');
  const [customerIdInput, setCustomerIdInput] = React.useState<string>('');

  React.useEffect(() => {
    if (params.id) {
      setResolvedName(params.id);
    }
  }, [params.id]);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  if (!customerIdentification) {
    return (
      <Container maxWidth="xl">
        <Grid2 container display="flex" flexDirection="column" style={{ maxHeight: '100vh', minHeight: '100vh' }}>
          <Grid2 size={12} flex={0}>
            <Box>
              <Paper elevation={3} sx={{ m: 0, mt: 2, p: 2, width: '100%' }}>
                <img
                  src="/assets/logos/crewlinq_logo.png"
                  alt="CrewLinQ"
                  style={{
                    width: 200 - 32,
                    margin: 0,
                    marginBottom: 16,
                    paddingBottom: 8,
                    borderBottom: `1px solid ${theme.custom.secondary}`,
                  }}
                />
                <Typography variant="h5">
                  {t('welcome')} {resolvedName}
                </Typography>
              </Paper>
            </Box>
          </Grid2>
          <Grid2 size={12} flex={1} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Box sx={{ mb: 2 }}>
              <Typography>{t('welcome-page.no-id')}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                label={t('welcome-page.enter-id')}
                variant="outlined"
                value={customerIdInput}
                onChange={(e) => setCustomerIdInput(e.target.value)}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  // Validate and set
                  setCustomerIdentification(customerIdInput);
                  window.location.href = `/welcome/${customerIdInput}`;
                }}
              >
                {t('welcome-page.submit')}
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    );
  }
  return (
    <Container maxWidth="xl">
      <Grid2 container display="flex" flexDirection="column" style={{ maxHeight: '100vh', minHeight: '100vh' }}>
        <Grid2 size={12} flex={0}>
          <Box>
            <Paper elevation={3} sx={{ m: 0, mt: 2, p: 2, width: '100%' }}>
              <img
                src="/assets/logos/crewlinq_logo.png"
                alt="CrewLinQ"
                style={{
                  width: 200 - 32,
                  margin: 0,
                  marginBottom: 16,
                  paddingBottom: 8,
                  borderBottom: `1px solid ${theme.custom.secondary}`,
                }}
              />
              <Typography variant="h5">
                {t('welcome')} {resolvedName}
              </Typography>
              <Stepper activeStep={activeStep}>
                {steps.map((label) => {
                  const stepProps: { completed?: boolean } = {};
                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel>{t(label)}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            </Paper>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ mt: 2, mb: 1 }}>
                {t('welcome-page.step')} {activeStep + 1}: {t(steps[activeStep])}
              </Typography>
              <Divider />
            </Box>
          </Box>
        </Grid2>
        <Grid2 size={12} flex={1} sx={{ px: 2 }}>
          {activeStep === 0 && <WelcomeContent />}
          {activeStep === 1 && <Questionnaire title="welcome-page.questionnaire" />}
          {activeStep === 2 && <XlsDownload />}
          {activeStep === 3 && <XlsUpload />}
          {activeStep >= 4 && <Onboarding title="welcome-page.finished" />}
        </Grid2>
        <Grid2 size={12} flex={0} sx={{ mb: 2 }}>
          <Divider />
          <Box>
            {activeStep === steps.length ? (
              <>
                <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button variant="outlined" onClick={handleReset}>
                    {t('welcome-page.reset')}
                  </Button>
                </Box>
              </>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  {t('welcome-page.back')}
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button variant="contained" color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? t('welcome-page.finish') : t('welcome-page.next')}
                </Button>
              </Box>
            )}
          </Box>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default WelcomePage;
