import { Timestamp } from '@firebase/firestore';
import {
  ArrayMinSize,
  Contains,
  IsArray,
  IsBoolean,
  IsDefined,
  IsEmail,
  IsNotEmpty,
  IsString,
  IsStrongPassword,
  Matches,
  MinLength,
  NotContains,
  ValidateIf,
} from 'class-validator';
import { eCrewlinqRights, eSupportedLanguages, eUserCapabilities, eUserRoles } from '../../Enums';
import { iCVPMitarbeiter } from '../../Interfaces/ConnextVivendiPep';
import { iDepartmentRef } from '../../Interfaces/Departments';
import { iPoolUserDetails } from '../../Interfaces/PoolManagement';
import { iQualificationRef } from '../../Interfaces/Qualifications';
import {
  iCrewlinqSettings,
  iEmailNotificationPreference,
  iLeasingDispatcherSettings,
  iNotificationPreference,
  iUser,
  iUserSettings,
} from '../../Interfaces/Users';

export class EmployeeDto implements iUser {
  department: iDepartmentRef;
  departmentIds: string[];
  capabilities: eUserCapabilities[];
  poolUserDetails: iPoolUserDetails | undefined;
  leasingDispatcherSettings: iLeasingDispatcherSettings | undefined;
  lastPushTokenUpdate: Timestamp | undefined;
  notifications: iNotificationPreference;
  rights: eCrewlinqRights[];
  crewlinqSettings: iCrewlinqSettings;
  id: string;
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  qualifications: iQualificationRef[];
  registered: Timestamp;
  lastLogin: Timestamp;
  pushToken: { data: string; type: string };
  role: eUserRoles;
  settings: iUserSettings | undefined;
  avatarUrl: string | undefined;
  isCustomLogin: boolean;
  username: string | undefined;
  extId: string | null;
  apiData: iCVPMitarbeiter | null;
  emailNotifications: iEmailNotificationPreference;
  allowSwapShifts: boolean;
}

export class CreateEmployeeDto implements iUser {
  emailNotifications: iEmailNotificationPreference;
  emailNotificationsList?: string[] | undefined;
  extId: string | null;
  apiData: null;
  department: iDepartmentRef;
  capabilities: eUserCapabilities[];
  poolUserDetails: iPoolUserDetails | undefined;
  leasingDispatcherSettings: iLeasingDispatcherSettings | undefined;
  lastPushTokenUpdate: Timestamp | undefined;
  notifications: iNotificationPreference;
  rights: eCrewlinqRights[];
  crewlinqSettings: iCrewlinqSettings;
  id: string;
  displayName: string;
  email: string;
  firstName: string;
  lastName: string;
  qualifications: iQualificationRef[];
  registered: Timestamp;
  lastLogin: Timestamp;
  pushToken: { data: string; type: string };
  role: eUserRoles;
  settings: iUserSettings | undefined;
  avatarUrl: string | undefined;
  isCustomLogin: boolean;
  username: string | undefined;
  departmentIds: string[];
  emailForNotifications?: string;
  allowSwapShifts: boolean;
}

export class CreateEmployeeFromRequestDto {
  @IsArray()
  @IsString({ each: true })
  departmentIds: string[];
  @IsArray()
  @IsString({ each: true })
  capabilities: string[];
  @IsNotEmpty()
  @IsString()
  displayName: string;
  @IsNotEmpty()
  @IsString()
  firstName: string;
  @IsNotEmpty()
  @IsString()
  lastName: string;
  @IsArray()
  @IsString({ each: true })
  @ArrayMinSize(1)
  qualifications: string[];
  @IsNotEmpty()
  @IsString()
  @Matches(new RegExp(`^(${Object.values(eSupportedLanguages).join('|')})$`), {
    message: `The language must be either: ${Object.values(eSupportedLanguages).join(', ')}`,
  })
  language: eSupportedLanguages;
  @IsBoolean()
  generatePassword: boolean;
  @ValidateIf((o) => !o.generatePassword)
  @IsNotEmpty()
  @IsString()
  @MinLength(8)
  @IsStrongPassword({
    minLength: 8,
    minLowercase: 1,
    minUppercase: 1,
    minNumbers: 0,
    minSymbols: 0,
  })
  password: string;

  // @IsOptional() is this needed?
  @ValidateIf((o) => o.email && o.username)
  @IsDefined({
    message: 'The user can either have a username or an email to login with, but not both.',
  })
  protected readonly checkOnlyOne: undefined;

  @ValidateIf((o) => !o.username)
  @IsString()
  @IsEmail()
  email: string;

  @ValidateIf((o) => !o.email)
  @IsNotEmpty()
  @IsString()
  @MinLength(3)
  @Contains('.', { message: 'The username must contain a dot.' })
  @NotContains(' ', { message: 'The username must not contain spaces.' })
  @NotContains('@', { message: 'The username must not contain @.' })
  username: string;

  @IsString()
  extId: string;
  apiData: iCVPMitarbeiter | null;
}

export class UpdateEmployeeDto extends CreateEmployeeDto {}
