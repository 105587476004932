import React from 'react';

interface iQuestionnaire {
  title?: string;
}

const Questionnaire: React.FC<iQuestionnaire> = function Questionnaire({ title }: iQuestionnaire): React.JSX.Element {
  return <div>{title}</div>;
};

export default Questionnaire;
