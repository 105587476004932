import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { iNews } from 'datamodel';

export const newsSlice = createSlice({
  name: 'news',
  initialState: [] as iNews[],
  reducers: {
    setNewsValue: (_, action: PayloadAction<iNews[]>) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setNewsValue } = newsSlice.actions;

export default newsSlice.reducer;
