export interface iManagerMostRequiredData {
  name: string;
  count: number;
}

export interface iManagerMostRequiredShift {
  name: string;
  data: {
    x: string;
    y: number;
  }[];
}
export interface iManagerDataForTable {
  id: string;
  displayName: string;
  department: string;
  acceptedOffers: number;
  assignedOffers: number;
}
export interface iManagerOfferLifecycle {
  id: string;
  createdAt: number;
  acceptedOn?: number;
  assignedOn?: number;
}

export interface iManagerLifecycleDiff {
  id: string;
  createdToAccepted: number;
  acceptedToAssigned: number;
  createdToAssigned: number;
}

export interface iManagerLifecycleAverageData {
  seconds: number;
  time: string;
  count?: number;
  onlyMinSec?: string;
  hoursMinSec?: string;
}

export interface iManagerTotalTimeSaved {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export interface iManagerUserNumbers {
  name: string;
  data: {
    x: string;
    y: number;
  }[];
}

export interface iManagerQuarter {
  name: string;
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export const oLifecycleAverageData = {
  seconds: 0,
  time: '',
};

export interface iManagerTopThreeLeasingEmployees {
  id: string;
  displayName: string;
  count: number;
  companyName: string;
}

export interface iManagerColumnChartWithMarkersData {
  x: string;
  y: number;
  goals: [
    {
      name: string;
      value: number;
      strokeHeight: number;
      strokeColor: string;
    },
  ];
}

export interface iManagerVolunteerShiftsDepartmentCount {
  volunteer: number;
  booked: number;
}

export interface iManagerReportData {
  offers: number;
  accepted: number;
  rejected: number;
  assigned: number;
  missed: number;
  avgLeadTime: number;
  avgPositiveResponsePerOffer: number;
  avgNegativeResponsePerOffer: number;
  tooLateResponse: number;
  fastestAccept: number;
  avgAccept: number;
  avgReject: number;
  shortTerm: number;
  mediumTerm: number;
  multiTenant: number;
  cancelled: number;
}

export interface iManagerChartData {
  name: string;
  label: string;
  offers: number;
  accepted?: number;
  rejected?: number;
  assigned: number;
  missed: number;
}
