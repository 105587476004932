import React from 'react';

interface iXlsDownload {
  title?: string;
}

const XlsDownload: React.FC<iXlsDownload> = function XlsDownload({ title }: iXlsDownload): React.JSX.Element {
  return <div>{title}</div>;
};

export default XlsDownload;
