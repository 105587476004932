import React from 'react';

interface iWelcomeContent {
  title?: string;
}

const WelcomeContent: React.FC<iWelcomeContent> = function WelcomeContent({
  title,
}: iWelcomeContent): React.JSX.Element {
  return <div>{title && <h1>{title}</h1>}</div>;
};

export default WelcomeContent;
