export enum QuestionnaireQuestionType {
  TEXT = 'text',
  MULTILINE = 'multiline',
  NUMBER = 'number',
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
  DATE = 'date',
  TIME = 'time',
  DATETIME = 'datetime',
  DATERANGE = 'daterange',
  TIMERANGE = 'timerange',
  SELECT = 'select',
  MULTISELECT = 'multiselect',
  EMAIL = 'email',
}

export interface iQuestionnaireQuestion {
  question: string;
  helperText: string;
  label: string;
  required: boolean;
  type: QuestionnaireQuestionType;
  options: iQuestionnaireQuestionOption[];
  answer?: iQuestionnaireQuestionAnswer[];
  id: string;
}
export interface iQuestionnaireQuestionOption {
  checked?: boolean;
  value?: string;
  label?: string;
  minValue?: number;
  maxValue?: number;
  minDate?: string;
  maxDate?: string;
  minTime?: string;
  maxTime?: string;
  preSetDate?: string;
  preSetTime?: string;
  startTime?: string;

  id: string;
}

export interface iQuestionnaireQuestionAnswer {
  checked?: boolean;
  textValue?: string;
  numberValue?: number;
  date?: string;
  time?: string;
  startDate?: string;
  endDate?: string;
  endTime?: string;
  optionId: string;
}

export interface iQuestionnaire {
  name: string;
  description: string;
  questions: iQuestionnaireQuestion[];
}
