/*
Provided example from supplier:

{

  "employee_id": "000042",

  "shift_name": "Einspringen <24h",

  "shift_starts_at": "2025-03-11T08:54:21.000000Z",

  "shift_ends_at": "2025-03-11T08:54:21.000000Z",

  "accepted_at": "2025-03-11T08:54:21.000000Z",

  "internal_note": "optional, um Shift zuordnen zu können"

}

For the ISO 8601 UTC timestamps with microseconds shown above, this format would be used:
moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]')
*/

import { IsString } from 'class-validator';
import { iShift } from '../../Interfaces/Shifts';

export class SendToValueAppDto {
  @IsString()
  employee_id: string;
  @IsString()
  shift_name: string;
  @IsString()
  shift_starts_at: string;
  @IsString()
  shift_ends_at: string;
  @IsString()
  accepted_at: string;
  @IsString()
  internal_note: string;
}

export class SendToValueAppMessageDto {
  valueAppId: string;
  displayName: string;
  whenString: string;
  shift: iShift;
  departmentName: string;
  acceptedAt: string;
  customerId: string;
  houseId: string;
}
