import { eUserRoles } from '../../Enums';

export class CuSuEmployeeListRequestResponse {
  message: string;
  users: CuSuEmployeeResponse[];
  stats: CuSuEmployeeListStats;
}

export class CuSuEmployeeListStats {
  general: {
    numberOfUsers: number;
    numberOfUsersWithPushToken: number;
    numberOfUsersWithLastLogin: number;
    numberOfUsersWithLastLogout: number;
    numberOfUsersWithEmail: number;
    numberOfUsersWithUsername: number;
    numberOfUsersLastSeenWithinLast30Days: number;
    numberOfUsersLastSeenWithinLast7Days: number;
  };
  admins: {
    numberOfAdmins: number;
    numberOfAdminsWithPushToken: number;
    numberOfAdminsWithLastLogin: number;
    numberOfAdminsWithLastLogout: number;
    numberOfAdminsWithEmail: number;
    numberOfAdminsWithUsername: number;
    numberOfAdminsLastSeenWithinLast30Days: number;
    numberOfAdminsLastSeenWithinLast7Days: number;
  };
  users: {
    numberOfUsers: number;
    numberOfUsersWithPushToken: number;
    numberOfUsersWithLastLogin: number;
    numberOfUsersWithLastLogout: number;
    numberOfUsersWithEmail: number;
    numberOfUsersWithUsername: number;
    numberOfUsersLastSeenWithinLast30Days: number;
    numberOfUsersLastSeenWithinLast7Days: number;
  };
}

export class CuSuEmployeeResponse {
  id: string;
  displayName: string;
  email: string;
  username: string;
  lastLogin: string;
  lastLogout: string;
  lastSeen: string;
  hasPushToken: boolean;
  lastPushToken: string;
  role: eUserRoles;
}

export class CuSuOfferListRequestResponse {
  message: string;
  lines: CuSuOfferListRequestResponseLine[];
}

export class CuSuOfferListRequestResponseLine {
  offerId: string;
  type: string;
  when: string;
  assigned: boolean;
  compensation: string | false;
  assignedTo: string | false;
  assignedAt: string | false;
  department: string;
  shiftName: string;
  shiftTime: string;
  icon: string;
  qualifications: string;
  nrAccepted: number;
  nrRejected: number;
  isCancelled: boolean;
  createdAt: string;
  cancelReason: string | false;
}
