/**
 * The user Record is the user record from Firestore at /users/{uid}
 * This contains the tenantId, customerId and type of user (leasing or not, internal or not, developer or not)
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { eSupportedLanguages, iUserRecord } from 'datamodel';

const initialState: Partial<iUserRecord> = {
  language: eSupportedLanguages.de,
  settings: {
    calendarView: 'month',
  },
};

export const userRecordSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRecordValue: (state, action: PayloadAction<Partial<iUserRecord>>) => ({ ...state, ...action.payload }),
    clearUserRecordValue: () => initialState,
  },
});

export const { setUserRecordValue, clearUserRecordValue } = userRecordSlice.actions;

export default userRecordSlice.reducer;
